<template>
  <section>
    <div class="content w-100">
      <div class="header">
        <div class="prefix-section-title">{{ $t('supporting-evolution-of') }}</div>
        <h2 class="section-title"><b>{{ $t('big') }}</b> {{ $t('companies') }}</h2>
      </div>
      <ul v-check-visible>
        <li><a href="https://www.mercadolivre.com.br/" rel="noopener" target="_blank"><img alt="Mercado Livre logo" width="250" height="64" data-src="./img/companies/meli.png"></a></li>
        <li><a href="https://contaazul.com/" rel="noopener" target="_blank"><img alt="Conta Azul logo" width="250" height="35" data-src="./img/companies/contaazul.png"></a></li>
        <li><a href="https://www.celk.com.br/" rel="noopener" target="_blank"><img alt="Celk Sistemas logo" width="250" height="90" data-src="./img/companies/celk.png"></a></li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'lm-companies'
};
</script>

<style scoped>
ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin: 3rem 0 1rem;
  row-gap: 1rem;
}
.header {
  margin-bottom: 4rem;
}
li {
  flex: 1 1 28%;
  margin: 1rem 2%;
}
img{
    transition:all .1s ease;
}
@media screen and (min-width: 1024px) {
  img:hover, ul.visible img{
      transform: scale(1.05);
  }
}
@media screen and (max-width: 768px) {
  .header {
    margin-bottom: 3rem;
  }
  img {
    max-width: 250px;
    min-width: 180px;
    width: 80%;
    height: unset;
  }
}
@media screen and (max-width: 480px) {
  .header {
    margin-bottom: 2rem;
  }
  img {
    max-width: 200px;
    min-width: 120px;
  }
}
</style>
