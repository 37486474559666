<template>
  <footer>
    <div class="content w-100">
      <div class="left">© 2018-{{ actualYear }} Lucas Mattos.</div>
      <div class="right">{{ $t('made-with') }} <svg viewBox="0 0 20 20" class="heart"><title>icon_heart</title> <path d="M10,5.6C7.4-0.1,0.1,1.2,0,7.9c0,3.7,3.5,5.1,5.9,6.5c2.3,1.4,3.9,3.4,4.2,4.2c0.2-0.8,2-2.8,4.1-4.3 c2.3-1.6,5.9-2.9,5.9-6.5C20,1.2,12.5,0.1,10,5.6z"></path> </svg> {{ $t('in') }} Florianópolis, SC.</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'lm-footer',
  data () {
    return {
      actualYear: new Date().getFullYear()
    }
  }
}
</script>

<style scoped>
footer {
  color: var(--gray-200);
  background: var(--gray-900);
  padding: 2rem 0;
  text-align: center;
}
.content {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  font-weight: 400;
  padding: 1rem 5rem;
}

.heart {
  fill: var(--red-500);
  width: 10px;
  height: 10px;
  margin: 0 5px;
}
@media screen and (max-width: 768px) {
  .content {
    flex-direction: column;
    row-gap: 0.5rem;
    padding: 1rem 0;
  }
}
</style>
