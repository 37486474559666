<template>
  <main>
    <Introduction id="introduction"/>
    <AboutMe id="aboutme" />
    <Skills id="skills" />
    <Experiences id="experiences" />
    <Languages id="languages"/>
    <Companies id="companies"/>
    <Contact id="contact"/>
  </main>
</template>

<script>
import Introduction from './components/introduction/Introduction.vue'
import AboutMe from './components/aboutme/AboutMe.vue'
import Skills from './components/skills/Skills.vue'
import Experiences from './components/experiences/Experiences.vue'
import Languages from './components/languages/Languages.vue'
import Companies from './components/companies/Companies.vue'
import Contact from './components/contact/Contact.vue'

export default {
  name: 'lm-main',
  components: {
    Introduction,
    AboutMe,
    Skills,
    Experiences,
    Languages,
    Companies,
    Contact
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.onScroll();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    onScroll() {
      const windowScroll = window.top.scrollY;
      const componentScrolls = {
        contact: document.getElementById("contact").offsetTop,
        companies: document.getElementById("companies").offsetTop,
        languages: document.getElementById("languages").offsetTop,
        experiences: document.getElementById("experiences").offsetTop,
        skills: document.getElementById("skills").offsetTop,
        aboutme: document.getElementById("aboutme").offsetTop,
        introduction: document.getElementById("introduction").offsetTop
      }

      let alreadySet = false;

      for (const componentScroll in componentScrolls) {
        if (!alreadySet && componentScrolls[componentScroll] - (document.getElementById(componentScroll).offsetHeight * 0.5) <= windowScroll) {
          document.getElementById(componentScroll).classList.add("active");
          alreadySet = true;
          continue;
        }

        document.getElementById(componentScroll).classList.remove("active");
      }
    }
  }
}
</script>
