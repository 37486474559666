export default {
  install(app) {
    window.checkVisible = new Set();

    window.addEventListener('scroll', () => {
      const screenDownSize = window.innerHeight*0.8;
      const screenUpSize = window.innerHeight*0.3;

      window.checkVisible.forEach(el => {
        const elSize = el.getBoundingClientRect();

        if (elSize.y < screenDownSize
          && screenUpSize < elSize.y) {
          el.classList.add('visible');
        } else {
          el.classList.remove('visible');
        }
      });
    });

    app.directive('check-visible', {
      mounted: function (el) {
        if (!window.checkVisible.has(el)) {
          window.checkVisible.add(el);
        }
      }
    });
  }
}