<template>
  <section>
    <div class="content w-100">
      <div>
        <div class="prefix-section-title">{{ $t('main-technologies-that') }}</div>
        <h2 class="section-title"><b>{{ $t('have') }}</b> {{ $t('skills') }}</h2>
        <ul class="technologies">
          <li>
            <div class="title">Languages</div>
            <ul class="skills" v-check-visible>
              <li><img data-src="./img/skills/java.png" width="88" height="88" alt="Java logo"><div class="name">Java</div></li>
              <li><img data-src="./img/skills/kotlin.png" width="88" height="88" alt="Kotlin logo"><div class="name">Kotlin</div></li>
              <li><img data-src="./img/skills/golang.png" width="88" height="88" alt="Golang logo"><div class="name">Golang</div></li>
              <li><img data-src="./img/skills/javascript.png" width="88" height="88" alt="Javascript logo"><div class="name">Javascript</div></li>
            </ul>
          </li>
          <li>
            <div class="title">Frameworks</div>
            <ul class="skills" v-check-visible>
              <li><img data-src="./img/skills/springboot.png" width="88" height="88" alt="Spring Boot logo"><div class="name">Spring Boot</div></li>
              <li><img data-src="./img/skills/vue-js.png" width="88" height="88" alt="Vue JS logo"><div class="name">Vue JS</div></li>
              <li><img data-src="./img/skills/react.png" width="88" height="88" alt="React logo"><div class="name">React</div></li>
              <li><img data-src="./img/skills/flutter.png" width="88" height="88" alt="Flutter logo"><div class="name">Flutter</div></li>
            </ul>
          </li>
          <li>
            <div class="title">Database</div>
            <ul class="skills" v-check-visible>
              <li><img data-src="./img/skills/postgresql.png" width="88" height="88" alt="PostgreSQL logo"><div class="name">PostgreSQL</div></li>
              <li><img data-src="./img/skills/mysql.png" width="88" height="88" alt="MySQL logo"><div class="name">MySQL</div></li>
              <li><img data-src="./img/skills/mongodb.png" width="88" height="88" alt="MongoDB logo"><div class="name">MongoDB</div></li>
              <li><img data-src="./img/skills/redis.png" width="88" height="88" alt="Redis logo"><div class="name">Redis</div></li>
            </ul>
          </li>
          <li>
            <div class="title">Observability</div>
            <ul class="skills" v-check-visible>
              <li><img data-src="./img/skills/kibana.png" width="88" height="88" alt="Kibana logo"><div class="name">Kibana</div></li>
              <li><img data-src="./img/skills/new-relic.png" width="88" height="88" alt="New Relic logo"><div class="name">New Relic</div></li>
              <li><img data-src="./img/skills/grafana.png" width="88" height="88" alt="Grafana logo"><div class="name">Grafana</div></li>
              <li><img data-src="./img/skills/datadog.png" width="88" height="88" alt="Datadog logo"><div class="name">Datadog</div></li>
            </ul>
          </li>
          <li>
            <div class="title">Cloud</div>
            <ul class="skills" v-check-visible>
              <li><img data-src="./img/skills/docker.png" width="88" height="88" alt="Docker logo"><div class="name">Docker</div></li>
              <li><img data-src="./img/skills/aws.png" width="88" height="88" alt="AWS Services logo"><div class="name">AWS</div></li>
              <li><img data-src="./img/skills/gcp.png" width="88" height="88" alt="GCP Services logo"><div class="name">GCP</div></li>
              <li><img data-src="./img/skills/azure.png" width="88" height="88" alt="Azure logo"><div class="name">Azure</div></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'lm-skills',
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const screenDownSize = window.innerHeight*0.6;
      const screenUpSize = window.innerHeight*0.5;

      window.a = this.$refs;

      for(let refIndex in this.$refs) {
        const ref = this.$refs[refIndex];
        const refSize = ref.getBoundingClientRect();
        
        if (screenDownSize > refSize.top
          && screenUpSize < refSize.bottom) {
          ref.classList.add('visible');
        } else {
          ref.classList.remove('visible');
        }
      }
    },
  }
};
</script>

<style scoped>
.technologies {
  padding: 2rem 0;
}
.technologies .title {
  writing-mode: vertical-rl;
  text-align: center;
  font-size: 1.2rem;
  background: var(--gray-700);
  color: var(--gray-200);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 0.5rem;
  font-weight: 500;
  transform: rotate(180deg);
}
.technologies > li {
  display: flex;
  padding: 1rem;
}
.skills {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background: var(--gray-200);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1rem;
}
.skills li {
  position: relative;
  padding: 0.25rem;
}
.skills .name {
  font-weight: 400;
  font-size: 1.2rem;
  width: 100%;
  margin-top: -10px;
}
li {
  list-style: none;
}
.skills li{
    text-align: center;
    transition: all .1s ease;
    flex: 1 1 0;
}
.skills li img {
  box-sizing: border-box;
}
@media screen and (min-width: 1024px) {
  .skills li:hover, .skills.visible li {
      transform: scale(1.1);
  }
}

@media screen and (max-width: 768px) { 
  .skills li img {
    padding: 5px;
  }
  .skills .name {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 480px) { 
  .skills li img {
    padding: 8px;
  }
  .skills .name {
    font-size: 1rem;
  }
}
</style>
