<template>
  <section>
    <div class="content w-100">
      <div class="about-me">
        <div class="prefix-section-title">{{ $t('knowing') }}</div>
        <h2 class="section-title"><b>{{ $t('about') }}</b> {{ $t('me') }}</h2>
        <p class="description">{{ $t('about-me-description') }}</p>
      </div>
      <div class="my-photo" v-check-visible>
        <img data-src="./img/aboutme/my-photo.png" width="288" height="280" alt="Foto de Lucas" />
      </div>
    </div>
    <div class="jobs">
        <div class="content w-100">
        <ul class="cards" v-check-visible>
          <li>
            <div>
              <img data-src="./img/aboutme/backend.png" width="75" height="75" alt="Software Engineer logo" />
            </div>
            Software Engineer
          </li>
          <li>
            <div>
              <img data-src="./img/aboutme/frontend.png" width="75" height="75" alt="Distributed Systems logo" />
            </div>
            Distributed Systems
          </li>
          <li>
            <div>
              <img data-src="./img/aboutme/security.png" width="75" height="75" alt="Information Security logo" />
            </div>
            Information Security
          </li>
          <li>
            <div>
              <img data-src="./img/aboutme/prototype.png" width="75" height="75" alt="Software Architect logo" />
            </div>
            Software Architect
          </li>
        </ul>
      </div>
    </div>
    <div class="years-section">
      <div class="content w-100">
        <ul class="years" v-check-visible>
          <li><div class="section-title">{{this.personalAge}} {{ $t('years') }}</div>{{ $t('lots-of-history') }}</li>
          <li><div class="section-title">{{this.developmentAge}} {{ $t('years') }}</div>{{ $t('developing-solutions') }}</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'lm-about-me',
  data() {
    return {
      personalAge: Math.floor((new Date() - new Date('1998-08-10T12:00:00')) / 31556926000),
      developmentAge: Math.floor((new Date() - new Date('2013-03-01T12:00:00')) / 31556926000 )
    }
  },
};
</script>

<style scoped>
.content {
  position: relative;
}
.description {
  margin-top: 1rem;
  text-align: justify;
}
.about-me {
  width: 50%
}
.my-photo {
  position: absolute;
  right: 10%;
  bottom: 0;
}
.my-photo img {
  display: block;
  margin: auto;
  transition: all .1s ease;
}
.jobs {
  background: var(--gray-200);
  box-shadow: inset 0 0px 80px -40px var(--gray-300);
}
.cards {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 1rem;
  column-gap: 2rem;
}
.cards li {
  padding: 5rem 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  text-wrap: nowrap;
  letter-spacing: -1px;
  color: var(--gray-300);
  background: var(--gray-700);
  border-radius: 15px;
  box-shadow: 0px 0px 40px -15px var(--gray-700);
  flex: 1 1 0;
  transition:all .1s ease;
}
.cards img {
  padding-bottom: 1.5rem;
}
.years-section {
  clip-path: inset(0 0 0 0);
}
.years-section::before {
  content: "";
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(./assets/background.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  filter: brightness(0.2);
}
.no-webp .years-section {
  background-image: url(./assets/background.png);
}
.years-section .content {
  box-shadow: inset 0 0 80px 0px #000;
}
.years {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
  text-wrap: nowrap;
  color: var(--gray-200)
}
.years .section-title {
  color: var(--gray-100);
}
.years li {
  padding: 1.5rem 3rem;
  border: 2px solid var(--gray-500);
  border-radius: 16px;
  background: #1a1a1aad;
  transition: all .1s ease;
  margin: 1rem;
  flex: 1 1 0;
}
@media screen and (min-width: 1024px) {
  .my-photo img:hover, .my-photo.visible img {
    width: 317px;
    height: 308px;
  }
  .cards li:hover, .cards.visible li {
      transform: scale(1.03);
  }
  .years li:hover, .years.visible li {
    transform: scale(1.03);
  }
}
@media screen and (max-width: 1024px) {
  .my-photo {
    right: 5%;
  }
  .my-photo img {
    width: 256px;
    height: 249px;
  }
  .about-me {
    width: 60%
  }
}
@media screen and (max-width: 768px) {
  .my-photo {
    right: 0;
    left: 0;
  }
  .my-photo img {
    width: 192px;
    height: 186px;
  }
  .about-me {
    width: 100%;
    padding-bottom: 10rem;
  }
  .cards {
    row-gap: 1rem;
    column-gap: 1rem;
  }
  .cards li {
    padding: 3rem 1rem;
    font-size: 1rem;
  } 
  .cards img {
    width: 50px;
    height: 50px;
    padding-bottom: 1rem;
  }
  .years li {
    padding: 1.2rem 1rem;;
    margin: 0.5rem;
  }
}
</style>
