<template>
  <section>
    <div class="content w-100">
      <div>
        <div class="prefix-section-title">{{ $t('making-a-difference-in') }}</div>
        <h2 class="section-title"><b>{{ $t('my') }}</b> {{ $t('experiences') }}</h2>
      </div>
      <div class="experiences">
        <div class="experience">
          <div class="card" v-check-visible>
            <div class="logo"><img data-src="./img/experiences/meli.jpg" width="70" height="70" alt="Mercado Livre logo" /></div>
            <div class="info">
              <div class="title">{{ $t('sr-software-engineer') }}</div>
              <div class="company">Mercado Livre Brasil</div>
              <div class="period">{{ $t('apr') }}/2022 - {{ $t('current') }}</div>
            </div>
          </div>
        </div>
        <div class="experience">
          <div class="card" v-check-visible>
            <div class="logo"><img data-src="./img/experiences/ca.png" width="70" height="70" alt="Conta Azul logo" /></div>
            <div class="info">
              <div class="title">{{ $t('sr-software-engineer') }}</div>
              <div class="company">Conta Azul</div>
              <div class="period">{{ $t('jun') }}/2021 - {{ $t('apr') }}/2022</div>
            </div>
          </div>
        </div>
        <div class="experience">
          <div class="card" v-check-visible>
            <div class="logo"><img data-src="./img/experiences/celk.jpg" width="70" height="70" alt="Celk Sistemas logo" /></div>
            <div class="info">
              <div class="title">{{ $t('sr-fullstack-developer') }}</div>
              <div class="company">Celk Sistemas</div>
              <div class="period">{{ $t('sep') }}/2019 - {{ $t('jun') }}/2021</div>
            </div>
          </div>
        </div>
        <div class="experience">
          <div class="card" v-check-visible>
            <div class="logo"><img data-src="./img/experiences/trindade.jpg" width="70" height="70" alt="Trindade Suplementos logo" /></div>
            <div class="info">
              <div class="title">{{ $t('owner-and-developer') }}</div>
              <div class="company">Trindade Suplementos</div>
              <div class="period">{{ $t('mar') }}/2017 - {{ $t('oct') }}/2020</div>
            </div>
          </div>
        </div>
        <div class="experience">
          <div class="card" v-check-visible>
            <div class="logo"><img data-src="./img/experiences/winkgamer.png" width="70" height="18" alt="Wink Gamer logo" /></div>
            <div class="info">
              <div class="title">{{ $t('owner-and-developer') }}</div>
              <div class="company">Wink Gamer</div>
              <div class="period">{{ $t('aug') }}/2013 - {{ $t('aug') }}/2018</div>
            </div>
          </div>
        </div>
      </div>
      <div class="dot first"></div>
      <div class="dot second"></div>
      <div class="dot third"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'lm-experiences'
};
</script>

<style scoped>
section {
  background: var(--gray-200);
  box-shadow: inset 0 0px 80px -40px var(--gray-300);
}
.experiences {
  margin: 4rem 0 2rem;
  position: relative;
}
.experiences::before {
  content: '';
  width: 0;
  height: calc(100% - 80px);
  position: absolute;
  border: 2px solid var(--gray-300);
  left: 50%;
  margin-top: 60px;
  margin-left: -2px;
}
.experiences::before {
  content: '';
  width: 0;
  height: calc(100% - 80px);
  position: absolute;
  border: 2px solid var(--gray-300);
  left: 50%;
  margin-top: 40px;
  margin-left: -2px;
  
}
.experience {
  display: flex;
  align-items: center;
  width: 50%;
  position: relative;
  z-index: 99;
  margin-top: 20px;
  margin-bottom: 20px;
}
.experience::before {
  content: "•";
  color: var(--gray-900);
  z-index: 999;
  position: absolute;
  font-size: 40px;
  padding-left: 20px;
  width: 10px;
  height: 3px;
  line-height: 7px;
  background: var(--gray-500);
  margin-top: -40px;
}
.card {
  padding: 1.5rem 2rem;
  border-radius: 16px;
  background: #ffffff;
  transition: all .1s ease;
  width: fit-content;
  z-index: 9999;
  transition:all .1s ease;
  display: flex;
  box-shadow: 0 0px 50px -30px var(--gray-300);
}
.experience:nth-of-type(odd) {
  margin-right: auto;
  text-align: right;
  padding-right: 30px;
}
.experience:nth-of-type(odd)::before {
  right: 0;
}
.experience:nth-of-type(odd) .card {
  margin-left: auto;
}
.experience:nth-of-type(odd) .logo {
  order: 1;
  margin-left: 1rem;
}
.experience:nth-of-type(even) {
  margin-left: auto;
  text-align: left;
  padding-left: 30px;
}
.experience:nth-of-type(even)::before {
  transform: rotate(180deg);
  left: 0;
  right: unset;
}
.experience:nth-of-type(even) .logo {
  order: 0;
  margin-right: 1rem;
}
.title {
  font-size: 1.2rem;
  font-weight: bold;
}
.period {
  font-size: 0.9rem;
  color: var(--gray-500);
}
.logo {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dot {
  margin: auto;
  background: var(--gray-300);
  width: 4px;
}
.dot.first {
  width: 3px;
  height: 20px;
  margin-top: -60px;
}
.dot.second {
  width: 2px;
  height: 13px;
  margin-top: 7px;
}
.dot.third {
  width: 1px;
  height: 10px;
  margin-top: 7px;
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 1024px) {
  .card:hover, .card.visible {
    transform: scale(1.05);
  }
  .experience:nth-of-type(odd) .card:hover, .experience:nth-of-type(odd) .card.visible {
    margin-right: 10px;
  }
  .experience:nth-of-type(even) .card:hover, .experience:nth-of-type(even) .card.visible {
    margin-left: 10px;
  }
}
@media screen and (max-width: 1088px) {
  .experiences::before {
    left: 15%;
  }
  .experience::before {
    left: 0;
    right: auto !important;
    transform: rotate(180deg);
  }
  .experience::before:nth-of-type(even) {
    left: 0;
    right: auto;
    transform: rotate(180deg);
  }
  .experience {
    margin: auto;
    width: 70%;
    margin-bottom: 2rem;
  }
  .experience:nth-of-type(odd) {
    padding-left: 30px;
    padding-right: unset;
    text-align: left;
  }
  .experience:nth-of-type(odd) .card {
    margin-left: unset;
  }
  .experience .card {
    width: 100%;
  }
  .experience:nth-of-type(odd) .logo {
    order: 0;
    margin-left: unset;
    margin-right: 1rem;
  }
  .dot {
    margin-left: calc(15% - 2px);
  }
}
@media screen and (max-width: 825px) {
  .experiences {
    margin: 2rem 0;
  }
  .title {
    font-size: 1rem;
  }
  .info {
    font-size: 0.8rem;
  }
  .experiences::before {
    left: 0;
  }
  .experience {
    width: 100%;
  }
  .dot {
    margin-left: -2px;
  }
  .card {
    padding: 0.8rem 1rem;
  }
}
</style>
