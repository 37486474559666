<template>
  <Header/>
  <Main/>
  <Footer/>
</template>

<script>
import Header from './components/header/Header.vue'
import Main from './pages/main/Main.vue'
import Footer from './components/footer/Footer.vue'

export default {
  name: 'App',
  components: {
    Header,
    Main,
    Footer
  },
  created() {
    this.loadLang();
  },
  methods: {
    loadLang() {
      this.$i18n.locale = localStorage.getItem('locale') || this.$i18n.locale;
    }
  },
}
</script>

<style>
@import './css/index.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--gray-900);
}
</style>
